.container {
    width: 100%;
    border-radius: 15px;
    position: relative;
    display: none;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.08);
    background-color: white;
    color: inherit;
    transition: all 0.2s ease;
    border: 1px solid #d3d3d3 !important;
    opacity: 0;
    margin-bottom: 30px;

    &:hover,
    &.open {
        box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
        border: 1px solid #b1b1b1 !important;
    }

    &.display{
        display: block;
    }

    &.open {
        .header .title {
            margin-top: 45px;
            margin-left: 4px;
        }
    }

    &.visible {
        opacity: 1;
    }

    &:not(.open):hover {
        .header .title {
            margin-top: -15px;

            &:after {
                opacity: 1;
            }
        }
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 24px;
        cursor: pointer;
        padding: 15px 20px;
        position: relative;

        @media (max-width: 992px) {
            font-size: 18px;
        }

        img {
            height: 40px;

            @media (max-width: 992px) {
                transform: scale(0.7);
                transform-origin: center right;
                height: 35px !important;
                width: 100px;
                object-fit: contain;
                object-position: right;
            }
        }

        .title {
            position: relative;
            display: block;
            transition: 0.2s ease all;

            &:after {
                content: 'auswählen';
                font-size: 14px;
                color: var(--green-color);
                position: absolute;
                top: 100%;
                margin-top: -5px;
                left: 0;
                opacity: 0;
                transition: 0.2s ease opacity;
            }
        }

    }

    .otherPayment {
        color: black;
        display: inline-block;
        background-image: url(../../assets/arrow-ios-back-fill.png);
        background-size: 5px;
        background-position: left 10px center;
        background-repeat: no-repeat;
        font-size: 14px;
        border-radius: 20px;
        background-color: #f2f2f2;
        padding: 5px 15px;
        padding-left: 25px;
        cursor: pointer;
        transition: 0.2s ease opacity;
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 9;

        @media (max-width: 992px) {
            left: 15px;
            top: 15px;
            background-color: transparent;
        }
    }

    .content {
        padding: 15px 20px;
        border-top: 1px solid #d3d3d3;
        padding-top: 20px;
        display: none;

        &.show {
            display: block;
        }
    }
}